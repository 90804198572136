<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <div class="card p-fluid">
        <form @submit.prevent="submitForm">
          <div class="p-field">
            <label for="name">Заголовок</label>
            <InputText
              id="name"
              v-model.trim="name.val"
              :required="true"
              @blur="clearValidity('name')"
              :class="!name.isValid ? 'p-invalid' : ''"
            />
          </div>
          <div class="p-field">
            <label for="descr">Описание</label>
            <Textarea
              id="descr"
              v-model.trim="descr.val"
              :required="true"
              @blur="clearValidity('descr')"
              :class="!descr.isValid ? 'p-invalid' : ''"
              rows="4"
            />
          </div>
          <div class="p-field">
            <label for="wtype" class="p-sr-only">Тип заявки</label>
            <Dropdown
              id="wtype"
              v-model="wtype.val"
              :options="workTypeList"
              :required="true"
              optionLabel="name"
              placeholder="Тип заявки"
              @blur="clearValidity('wtype')"
              :class="!wtype.isValid ? 'p-invalid' : ''"
            />
          </div>

          <div
            class="p-field"
            v-if="
              wtype.val &&
              permissions['change_checklist_template_while_creating_task']
            "
          >
            <label for="checklistTemplate" class="p-sr-only">
              Шаблон чеклиста
            </label>
            <Dropdown
              id="checklistTemplate"
              v-model="checklistTemplate.val"
              :options="sortedTemplates"
              optionLabel="name"
              :required="true"
              placeholder="Выбор чеклиста"
              @blur="clearValidity('checklistTemplate')"
              :class="!checklistTemplate.isValid ? 'p-invalid' : ''"
            />
          </div>

          <div class="p-field">
            <h5>Файлы</h5>
            <FileUpload
              name="demo[]"
              chooseLabel="Выберите файлы"
              cancelLabel="Отмена"
              :auto="true"
              :customUpload="true"
              :multiple="true"
              @uploader="myUploader"
              :showUploadButton="false"
              :showCancelButton="false"
            >
              <template #empty>
                <p>Загрузите файл</p>
              </template>
            </FileUpload>
          </div>
        </form>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-fluid p-formgrid">
        <div class="p-field" v-if="role !== ROLE.EMPLOYEE_OF_CLIENT">
          <label for="client">Клиент</label>
          <div class="dropdown-with-button">
            <Dropdown
              id="client"
              ref="client"
              v-model="client.val"
              :options="clients"
              :required="true"
              :filter="true"
              optionLabel="nameText"
              placeholder="Выберите клиента"
              emptyFilterMessage="Совпадений не найдено"
              emptyMessage="Совпадений не найдено"
              @blur="clearValidity('client')"
              @change="loadEmployeeForClient"
              :class="[
                !client.isValid ? 'p-invalid' : '',
                client?.val?.verified === false && client?.val?.debtor === true
                  ? 'selected-debtor'
                  : '',
              ]"
              class="dropdown-client"
            >
              <template #option="slotProps">
                <div
                  :class="
                    slotProps.option.verified === false &&
                    slotProps.option.debtor === true
                      ? 'debtor-row'
                      : ''
                  "
                >
                  {{ slotProps.option.nameText }}
                </div>
              </template>
            </Dropdown>
            <Button
              class="search-button"
              icon="pi pi-search"
              @click="openClient"
            />
          </div>
        </div>
        <GetDebtButton
          v-if="permissions['get_debt']"
          class="p-mb-3"
          :client-uid="client.val?.uid"
        />
        <div class="p-field">
          <label for="regionId">Регион</label>
          <Dropdown
            id="regionId"
            v-model="regionId.val"
            :options="regionsList"
            :required="true"
            optionLabel="name"
            optionValue="uid"
            :filter="true"
            placeholder="Выберите регион заявки"
            emptyFilterMessage="Совпадений не найдено"
            emptyMessage="Регионы не найдены"
            :disabled="isLoading || !client.val"
          />
        </div>
        <div class="p-field" v-if="role !== ROLE.EMPLOYEE_OF_CLIENT">
          <label for="employee">Сотрудник</label>
          <Dropdown
            id="employee"
            v-model="employee.val"
            :options="employeeList"
            :required="true"
            optionLabel="nameText"
            placeholder="Выберите сотрудника"
            emptyMessage="Сотрудники не найдены"
            @blur="clearValidity('employee')"
            :class="!employee.isValid ? 'p-invalid' : ''"
            :disabled="!client.val || employeeList.length === 0"
          />
        </div>
        <div class="p-field">
          <label for="technic">Техника</label>
          <Dropdown
            id="technic"
            v-model="technic.val"
            :options="technicList"
            :required="true"
            :filter="true"
            optionLabel="techLabel"
            :optionDisabled="disableInWork"
            placeholder="Выберите технику"
            emptyFilterMessage="Совпадений не найдено"
            emptyMessage="Техника не найдена"
            @blur="clearValidity('technic')"
            :class="!technic.isValid ? 'p-invalid' : ''"
            :disabled="!client.val || technic.length === 0 || isLoading"
          >
            <template #option="slotProps">
              <div
                class="p-d-flex p-ai-center"
                :class="
                  isWarrantyEnded(slotProps?.option?.serviceEndTime)
                    ? ''
                    : 'p-dropdown-items'
                "
              >
                <i
                  v-if="slotProps.option.inWork === true"
                  class="pi pi-cog p-mr-2"
                />
                <div>
                  {{ slotProps.option.techLabel }}
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field">
          <label for="name">Район фактического нахождения техники</label>
          <InputText
            id="district"
            v-model.trim="district.val"
            :required="true"
            @blur="clearValidity('district')"
            :class="!district.isValid ? 'p-invalid' : ''"
          />
        </div>
        <div class="p-field">
          <label for="name">Наработка</label>
          <InputText
            id="elaboration"
            v-model.trim="elaboration.val"
            :required="true"
            @blur="clearValidity('elaboration')"
            :class="!elaboration.isValid ? 'p-invalid' : ''"
          />
        </div>
        <div class="p-field" v-if="role !== ROLE.EMPLOYEE_OF_CLIENT">
          <label for="engineer"> Ответственный инженер </label>
          <Dropdown
            id="engineer"
            v-model="engineer.val"
            :options="engineerList"
            :required="true"
            :filter="true"
            optionLabel="nameText"
            emptyFilterMessage="Совпадений не найдено"
            @blur="clearValidity('engineer')"
            :class="!engineer.isValid ? 'p-invalid' : ''"
          >
            <template #option="slotProps">
              {{ slotProps.option.nameText }}
              <br />
              {{ slotProps.option.serviceInfo }}
            </template>
          </Dropdown>
        </div>
        <Button
          label="Создать заявку"
          @click="submitForm"
          :disabled="isLoading"
        ></Button>

        <div
          v-if="userData.phone === '+7-000-000-00-01'"
          class="p-mt-4 p-d-flex p-flex-row"
        >
          <InputText v-model="generateTaskAmount" />
          <Button label="Сгенерить заявку" @click="generateRandomTestTask" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import Compressor from "compressorjs";
import { mapGetters } from "vuex";
import { CHECKLIST_TYPE } from "@/checklist_types/checklist_types";
import { getRoleText } from "@/models/roles";
import { ROLE } from "@/models/roles";
import GetDebtButton from "@/components/GetDebtButton";

export default {
  name: "TaskCreate",
  components: { GetDebtButton },
  data() {
    return {
      ROLE,
      debt: "",
      regionsList: [],
      employeeList: [],
      technicList: [],
      defaultEngineer: { nameText: "-- Инженер не назначен --" },
      debtButtonLoading: false,
      checklistTemplatesList: [],
      imageTypes: ["image/png", "image/jpeg", "image/jpg"],
      checklistTemplate: {
        val: null,
        isValid: true,
      },
      name: {
        val: "",
        isValid: true,
      },
      descr: {
        val: "",
        isValid: true,
      },
      wtype: {
        val: "",
        isValid: true,
      },
      client: {
        val: "",
        isValid: true,
      },
      regionId: {
        val: null,
      },
      employee: {
        val: "",
        isValid: true,
      },
      engineer: {
        val: "",
        isValid: true,
      },
      technic: {
        val: "",
        isValid: true,
      },
      district: {
        val: "",
        isValid: true,
      },
      elaboration: {
        val: "",
        isValid: true,
      },
      isLoading: false,
      formIsValid: false,
      formFiles: [],
      generateTaskAmount: 1,
    };
  },
  created() {
    this.loadEmployes();
    this.loadFromLocalStorage();
    this.loadTemplates();
    if (this.engineer.val === null) {
      this.engineer.val = this.defaultEngineer;
    }
    window.addEventListener("refreshEmployeeCompanySet", () => {
      this.loadEmployes();
    });
  },
  computed: {
    ...mapGetters({
      engineers: "myprofiles/engineers",
      clientsList: "myprofiles/clients",
      selectedRegions: "selectedUserRegions",
      userData: "userData",
      regions: "data/regions",
      permissions: "getPermissions",
    }),
    sortedTemplates() {
      let sortedByWorkType = this.checklistTemplatesList.filter((ctl) => {
        return ctl?.type === this.wtype?.val?.value;
      });

      if (
        this.role === ROLE.SALES_MANAGER &&
        this.wtype?.val?.value === CHECKLIST_TYPE.SELF_INTERNAL
      ) {
        return sortedByWorkType.filter((ctl) => {
          return ctl.id === 12;
        });
      }

      return sortedByWorkType;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    workTypeList() {
      if (this.role === ROLE.SALES_MANAGER) {
        const availableWorkTypes = [
          CHECKLIST_TYPE.SELF_INTERNAL,
          CHECKLIST_TYPE.INTERNAL,
        ];
        return this.$store.getters["data/workTypes"].filter((type) => {
          return availableWorkTypes.includes(type.value);
        });
      }
      return this.$store.getters["data/workTypes"];
    },
    clients() {
      if (this.role === ROLE.SALES_MANAGER) {
        const companies = this.$store.getters["userCompanies"];
        return this.clientsList.filter((client) => {
          return companies.find((company) => company.uid === client.uid);
        });
      }

      if (this.selectedRegions.length) {
        return this.clientsList.filter((client) => {
          return this.selectedRegions.some((region) =>
            client.regionIds.includes(region.uid)
          );
        });
      }

      return this.clientsList;
    },
    engineerList() {
      return [this.defaultEngineer].concat(
        this.engineers.map((e) => {
          return {
            ...e,
            nameText: `${e.firstname} ${e.lastname}`,
            serviceInfo: `[${getRoleText(e.role)}] Заявок: ${e.taskCount}`,
          };
        })
      );
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
  watch: {
    sortedTemplates: function (newValue) {
      if (this.permissions["change_checklist_template_while_creating_task"]) {
        this.checklistTemplate.val = null;
        if (newValue.length === 1) {
          this.checklistTemplate.val = newValue[0];
        }
      }
    },
  },
  methods: {
    generateRandomString() {
      return (Math.random() + 1).toString(36).substring(2);
    },
    async generateRandomTestTask() {
      for (let i = 0; i < this.generateTaskAmount; i += 1) {
        try {
          const actionPayload = {
            header: this.generateRandomString(),
            description:
              "\nРайон фактического нахождения техники: " +
              this.generateRandomString(),
            company: this.clients[2],
          };
          this.client.val = this.clients[2];
          await this.loadEmployeeForClient();
          actionPayload.technic = this.technicList[0];
          actionPayload.employee = this.employeeList[0];
          actionPayload.regionId = this.client.val.regionIds[0];

          actionPayload.workType = this.workTypeList[0].value;
          this.wtype.val = this.workTypeList[0];
          actionPayload.checklistTemplate = this.sortedTemplates[3];

          if (this.engineer.val.nameText !== this.defaultEngineer.nameText) {
            actionPayload.engineer = this.engineer.val;
          }

          const newTask = await this.$store.dispatch(
            "myprofiles/createTask",
            actionPayload
          );
          await this.$router.push(`/task/${newTask.id}`);
        } catch (err) {
          console.log(err);
        }
      }
    },

    async loadTemplates() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        this.checklistTemplatesList = await response.json();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },

    isWarrantyEnded(newDate) {
      if (!newDate) return true;
      let date = new Date(newDate);
      let currentDate = Date.now();
      return currentDate > date;
    },

    getDateFromIso(newDate) {
      if (!newDate) return "";
      let date = new Date(newDate);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return `Дата окончания гарантии [${dt}.${month}.${year}]`;
    },

    disableInWork(option) {
      if (option.inWork === true) {
        return option;
      }
      return false;
    },

    async myUploader(event) {
      this.formFiles = event.files;
    },

    openClient() {
      this.saveToLocalStorage();
      this.$router.push({
        name: "ClientDetail",
        params: { id: this.client.val.uid, isFromCreateTask: true },
      });
    },

    clearValidity(input) {
      this[input].isValid = true;
    },

    async loadEmployes() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("myprofiles/loadEmployes");
      } catch (error) {
        console.error(error);
      }

      if (this.role === ROLE.EMPLOYEE_OF_CLIENT) {
        const userData = this.$store.getters.userData;
        this.client.val = userData.company;
        this.client.val = JSON.parse(
          localStorage.getItem("employeeCurrentCompany")
        );
        await this.loadEmployeeForClient();
      }

      this.isLoading = false;
    },

    async loadEmployeeForClient() {
      if (this.client?.val?.uid) {
        this.regionsList = this.regions.filter((region) => {
          return this.client.val.regionIds.includes(region.uid);
        });
        this.regionId.val = this.regionsList[0].uid;

        this.employeeList = await this.$store.dispatch(
          "myprofiles/loadEmployeeForClient",
          this.client.val.uid
        );
        this.employeeList = this.employeeList.filter((e) => {
          return e.block === false;
        });

        this.technicList = await this.$store.dispatch(
          "myprofiles/loadTechnicForClient",
          this.client.val.uid
        );

        this.technicList.map((t) => {
          t.techLabel += this.getDateFromIso(t.serviceEndTime);

          if (t?.placeOfStay) {
            t.techLabel += t?.placeOfStay;
          } else {
            t.techLabel += "";
          }
        });
      } else {
        this.employeeList = [];
        this.technicList = [];
      }
    },

    validateForm() {
      this.formIsValid = true;
      this.name.isValid = true;
      this.descr.isValid = true;
      this.wtype.isValid = true;
      this.elaboration.isValid = true;
      this.client.isValid = true;
      this.employee.isValid = true;
      this.engineer.isValid = true;
      this.technic.isValid = true;
      this.checklistTemplate.isValid = true;
      this.district.isValid = true;

      if (!this.name.val) {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (!this.descr.val) {
        this.descr.isValid = false;
        this.formIsValid = false;
      }
      if (!this.wtype.val) {
        this.wtype.isValid = false;
        this.formIsValid = false;
      }
      if (!this.client.val) {
        this.client.isValid = false;
        this.formIsValid = false;
      }
      if (!this.technic.val) {
        this.technic.isValid = false;
        this.formIsValid = false;
      }

      if (this.permissions["change_checklist_template_while_creating_task"]) {
        if (!this.checklistTemplate.val) {
          this.checklistTemplate.isValid = false;
          this.formIsValid = false;
        }
      }
      // проверяем только для оператора
      if (this.$store.getters.getRole === ROLE.OPERATOR && !this.employee.val) {
        this.employee.isValid = false;
        this.formIsValid = false;
      }
    },

    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          success: resolve,
          error: reject,
        });
      });
    },

    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      let fullDescription = this.descr.val;

      fullDescription +=
        "\nРайон фактического нахождения техники: " +
        (this.district.val || "-");

      fullDescription += "\nНаработка: " + (this.elaboration.val || "-");

      const actionPayload = {
        header: this.name.val,
        description: fullDescription,
        workType: this.wtype.val.value,
        company: this.client.val,
        technic: this.technic.val,
        regionId: this.regionId.val,
      };

      if (!this.permissions["change_checklist_template_while_creating_task"]) {
        const ctlForSending = this.sortedTemplates;
        if (ctlForSending.length === 1) {
          actionPayload["checklistTemplate"] = ctlForSending[0];
        }
      } else {
        actionPayload["checklistTemplate"] = this.checklistTemplate.val;
      }

      if (this.employee.val !== "") {
        actionPayload.employee = this.employee.val;
      }
      if (this.engineer.val.nameText !== this.defaultEngineer.nameText) {
        actionPayload.engineer = this.engineer.val;
      }

      try {
        const newTask = await this.$store.dispatch(
          "myprofiles/createTask",
          actionPayload
        );

        // загружаем файлы в новую задачу
        for (const f of this.formFiles) {
          let file = f;
          if (this.imageTypes.includes(file.type)) {
            file = await this.compressImage(file);
            if (file instanceof Blob) {
              file = new File([file], file.name);
            }
          }
          console.log(file);
          const data = new FormData();
          data.append("file", file);

          await fetch(`${environment.apiUrl}/tasks/${newTask.id}/files`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
            body: data,
          });
        }

        this.formFiles = [];
        await this.$router.push(`/task/${newTask.id}`);
        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Заявка ${actionPayload.header} создана!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось создать заявку";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при создании заявки!",
          detail: error,
          life: 6000,
        });
        console.log(err);
      }

      this.isLoading = false;
    },

    saveToLocalStorage() {
      localStorage.setItem("name", this.name.val);
      localStorage.setItem("descr", this.descr.val);
      localStorage.setItem("wtype", JSON.stringify(this.wtype.val));
      localStorage.setItem("client", JSON.stringify(this.client.val));
      localStorage.setItem("regionId", this.regionId.val);
      localStorage.setItem("employee", JSON.stringify(this.employee.val));
      localStorage.setItem("engineer", JSON.stringify(this.engineer.val));
      localStorage.setItem("technic", JSON.stringify(this.technic.val));
      localStorage.setItem("elaboration", JSON.stringify(this.elaboration.val));
      localStorage.setItem("district", JSON.stringify(this.district.val));
    },

    async loadFromLocalStorage() {
      this.name.val = localStorage.getItem("name");
      this.descr.val = localStorage.getItem("descr");

      if (!this.name.val) {
        this.name.val = "";
      }

      if (!this.descr.val) {
        this.descr.val = "";
      }
      this.wtype.val = JSON.parse(localStorage.getItem("wtype"));
      this.client.val = JSON.parse(localStorage.getItem("client"));
      this.regionId.val = localStorage.getItem("regionId");
      this.employee.val = JSON.parse(localStorage.getItem("employee"));
      this.engineer.val = JSON.parse(localStorage.getItem("engineer"));
      this.technic.val = JSON.parse(localStorage.getItem("technic"));
      this.elaboration.val = JSON.parse(localStorage.getItem("elaboration"));
      this.district.val = JSON.parse(localStorage.getItem("district"));

      localStorage.removeItem("name");
      localStorage.removeItem("descr");
      localStorage.removeItem("wtype");
      localStorage.removeItem("client");
      // как клиента восстановили - подгрузить и его сотрудников
      localStorage.removeItem("regionId");
      await this.loadEmployeeForClient();
      localStorage.removeItem("employee");
      localStorage.removeItem("engineer");
      localStorage.removeItem("technic");
      localStorage.removeItem("elaboration");
      localStorage.removeItem("district");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.p-dropdown-panel) {
  max-width: 300px;
}
.dropdown-with-button {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.dropdown-client {
  width: 95%;
}
.search-button {
  width: 5%;
  min-width: 20px;
}
@media (max-width: 1100px) {
  .dropdown-client {
    width: 90%;
  }
  .search-button {
    width: 10%;
  }
}
.p-dropdown-items {
  background-color: #93ffc2 !important;
}
.selected-debtor {
  background-color: #ffe6e6 !important;
}
</style>
<style>
.debtor-row {
  background-color: #ffe6e6 !important;
}
</style>
