export const CHECKLIST_TYPE = {
  TO: "TO",
  COMMERCIAL_REPAIR: "COMMERCIAL_REPAIR",
  COMMISSIONING: "COMMISSIONING",
  SELF_INTERNAL: "SELF_INTERNAL",
  INTERNAL: "INTERNAL",
};
export const CHECKLIST_TYPES_SELECT = [
  { name: "ТО", value: "TO" },
  { name: "Гарантийный ремонт", value: "WARRANTY_REPAIR" },
  { name: "Коммерческий ремонт", value: "COMMERCIAL_REPAIR" },
  { name: "Ввод в эксплуатацию", value: "COMMISSIONING" },
  { name: "Внутренняя Агротрак", value: "SELF_INTERNAL" },
  { name: "Внутренняя Клиент", value: "INTERNAL" },
];
